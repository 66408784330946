const regions = {
  bayern: "Bayern",
  berlin: "Berlin",
  brandenburg: "Brandenburg",
  bw: "Baden-Württemberg",
  hamburg: "Hamburg",
  hessen: "Hessen",
  muenchen: "München",
  mv: "Mecklenburg-Vorpommern",
  niedersachsen: "Niedersachsen",
  nrw: "Nordrhein-Westfalen",
  rp: "Rheinland-Pfalz",
  sa: "Sachsen-Anhalt",
  sachsen: "Sachsen",
  sh: "Schleswig-Holstein",
  thueringen: "Thüringen",
};

export default regions;
