import React from 'react';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import ReactTable from './ReactTable';
import axios from "./AxiosConfig";


export default function Artenliste() {

  const [search, setSearch] = React.useState('');
  const [arten, setArten] = React.useState([]);

  React.useEffect(() => {
    // Get arten from API /arten/liste
    axios.get("/arten/liste")
      .then((response) => {
        console.log(response.data);
        setArten(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleSearch = (event) => {
    setSearch(event.target.value);
  };

  const dataFiltered = arten.filter((row) =>
    row.vollstaendiger_artname.toLowerCase().includes(search.toLowerCase()) ||
    row.deutscher_name.toLowerCase().includes(search.toLowerCase())
  );

  const data = React.useMemo(
    () => dataFiltered, [dataFiltered]
  );

  const columns = React.useMemo(
    () => [
      // {
      //   Header: "#",
      //   accessor: "id",
      //   Cell: ({ cell: { value } }) =>
      //     <div
      //       style={{
      //         textAlign: "left"
      //       }}
      //     ><b>{value}</b></div>,
      // },
      {
        Header: 'Familiengruppe',
        accessor: 'familiengruppe',
        Cell: ({ cell: { value } }) => <i>{value}</i>
      },
      {
        Header: 'Vollständiger Artname',
        accessor: 'vollstaendiger_artname',
        Cell: ({ cell: { value } }) => <i>{value}</i>
      },
      {
        Header: 'Deutscher Name',
        accessor: 'deutscher_name',
      },
      {
        Header: 'RLD',
        accessor: 'rl_de',
      },
      {
        Header: 'Kommentar',
        accessor: 'kommentar',
      },
      {
        Header: 'Nomenklatur und Synonymie',
        accessor: 'nomenklatur_und_synonymie',
      },
    ],
    []
  );

  return (
    <Container>
      <br></br>
      <h4>Arten</h4>
      <hr></hr>
      <h6>Artenliste der deutschen Stechimmenarten</h6>
      <p>
        Hier werden alle Arten angezeigt, die aktuell in Deutschland vorkommen.
        Neben den Stechimmen sind zusätzlich noch einige kleinere Hymenopterenfamilien mit aufgenommen.
        Die Arten werden nach den Familiengruppen sortiert.
        Die genauen Verwandtschaftsverhältnisse sind bei den Artsteckbriefen aufgeführt.
      </p>
      <Form.Control
        id={"art"}
        type={'text'}
        value={search}
        onChange={handleSearch}
        placeholder='Hier kann nach einer Gattung oder Art gesucht werden'
      />
      <br></br>
      <ReactTable data={data} columns={columns} pageSize={50} />
      <br></br>
    </Container>
  )
}
