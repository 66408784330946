import React from 'react';
import ReactTable from './ReactTable';

export default function MapTable(props) {
  const data = React.useMemo(
    () =>
      props.observations,
    [props.observations]
  );

  const columns = React.useMemo(
    () => [
      {
        Header: 'Art',
        accessor: 'art',
        Cell: ({ cell: { value } }) => <i>{value}</i>
      },
      {
        Header: 'Datenquelle',
        accessor: 'datenquelle',
      },
      {
        Header: 'Jahr',
        accessor: 'jahr',
      },
    ],
    []
  );

  let mapTableUI;

  if (props.tk25Quadrant !== null) {
    mapTableUI = <>
      <h5>{props.tk25Quadrant}</h5>
      <ReactTable data={data} columns={columns} />
    </>
  } else {
    mapTableUI = <p style={{ color: "grey" }}>
      Es muss zuerst die Familie und dann eine Art ausgewählt werden, um die Verbreitung der Art auf der Karte anzuschauen.
      Bei Klick auf einen Kreis werden die zugehörigen Beobachtungen als Tabelle angezeigt.
    </p>;
  }

  return (
    mapTableUI
  )
}
