import React from 'react';
import { useForm } from 'react-hook-form';
import Form from 'react-bootstrap/Form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';

import axios from './AxiosConfig';
import { useAuth } from './AuthContext';
import SubmitButton from './SubmitButton';
import './Auth.css';


export default function PasswordResetEmail(props) {

  const navigate = useNavigate();
  const auth = useAuth();

  const validationSchema = Yup.object({
    email: Yup.string()
      .email('E-Mail Addresse ungültig!')
      .required('Pflichtfeld')
  });

  const { register, handleSubmit, watch, formState: { errors } } = useForm({
    resolver: yupResolver(validationSchema)
  });
  
  const onSubmit = (values) => {
    console.log(values);
    auth.getPasswordResetCode(() => {
      navigate("/passwortneu");
    }, values.email);
  }

  return (
    <div className="auth-form">
      <div className="auth-form-body">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Text>
            Bitte E-Mail Adresse angeben, dann wird ein Verifizierungscode für das Zurücksetzen des Passworts
            zugeschickt. Damit kann danach ein neues Passwort vergeben werden.
          </Form.Text>
          <br></br>
          <br></br>
          <Form.Group controlId="email">
            <Form.Label>E-Mail</Form.Label>
            <Form.Control
              type={'email'}
              {...register("email")}
            />
          </Form.Group>
          <br></br>
          <SubmitButton text={"Passwort zurücksetzen"} />
        </Form>
      </div>
    </div>
  )
}
