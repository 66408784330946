import './App.css';
import { BrowserRouter as Router } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';

import Navbar from './components/Navbar';
import Routes from './components/Routes';
// import Footer from './components/Footer';
import ProvideAuth from './components/Auth';

function App() {
  return (
    <div>
      <ProvideAuth>
        <Router>
          <Toaster position="bottom-right"></Toaster>
          <div className="wrapper">
            <Navbar />
            <Routes />
            {/* <Footer /> */}
          </div>
        </Router>
      </ProvideAuth>
    </div>
  );
}

export default App;
