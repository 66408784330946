import React, { useEffect, useRef, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import { toast } from "react-hot-toast";
import Map from './Map';
import InputForm from './InputForm';
import AdminMapTable from './AdminMapTable';
import { useRegion } from './RegionContext';
import axios from './AxiosConfig';

export default function AdminVerbreitung(props) {

  // const map = useRef(null);
  const region = useRegion();
  const [selectedId, setSelectedId] = useState(null);  
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = (id) => {
    setSelectedId(id);
    setShow(true);
  }

  const [art, setArt] = useState("");
  const [observations, setObservations] = useState([]);
  const [observationsTable, setObservationsTable] = useState([]);
  const [tk25Quadrant, setTK25Quadrant] = useState(null);

  const getObservations = async (art) => {
    try {
      let query = `beobachtungen/admin?art=${art}`;
      if (region.region !== "deutschland") {
        query = query.concat(`&bundesland_darstellung=${region.regionName}`);
      }
      const response = await axios.get(query);
      if (response.data) {
        console.log(response.data);
        setObservations(response.data);
      }
    } catch (error) {
      console.error(error);
    }
  };


  const filterObservations = (event) => {
    console.log(event);
    if (event) {
      const art = event.value;
      setArt(art);
      getObservations(art);
      // const current = defaultObs.filter((row) => row.art === art);
      // console.log(art);
      // setObservations(current);
      // setObservationsTable(current);
      setTK25Quadrant(null);
    }
  }

  const handleMapClick = (tk25) => {
    console.log(tk25);
    setTK25Quadrant(tk25);
    let current = observations.filter((row) => row.tk25 === tk25);
    if (art) {
      current = current.filter((row) => row.art === art);
    }
    console.log(current);
    setObservationsTable(current);
  }

  function deleteObservation() {
    console.log(selectedId);
    axios.post("/beobachtungen/delete", {
      id: selectedId
    })
      .then((response) => {
        console.log(response.data);
        toast.success("Beobachtung gelöscht.");

        // Remove the deleted observation from the state
        let newObservations = observations.filter(observation => observation.id !== selectedId);
        setObservations(newObservations);
        if (art) {
          newObservations = newObservations.filter((row) => row.art === art);
        }
        newObservations = newObservations.filter((row) => row.tk25 === tk25Quadrant);
        setObservationsTable(newObservations);
      })
      .catch((error) => {
        console.log(error);
        toast.error("Fehler beim Löschen der Beobachtung.");
      });
    console.log(`Deleting observation with id ${selectedId}`);
    handleClose();
  }

  return (
    <>
      <Container>
        <br></br>
        <Row>
          <InputForm
            art={art}
            onChange={filterObservations}
            setArt={setArt}
            setTK25Quadrant={setTK25Quadrant}
          />
        </Row>
        <hr></hr>
        <Row>
          <Col md={6} xs={12}>
            <Map
              // map={map}
              region={region}
              handleMapClick={handleMapClick}
              observations={observations}
            />
          </Col>
          <Col md={6} xs={12}>
            <AdminMapTable
              observations={observationsTable}
              tk25Quadrant={tk25Quadrant}
              deleteObservation={deleteObservation}
              show={show}
              handleClose={handleClose}
              handleShow={handleShow}
            />
          </Col>
        </Row>
      </Container>
    </>
  )
}
