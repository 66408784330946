import React from 'react';
import { Rectangle } from 'react-leaflet';

export default function TK25Raster({
  raster,
  keyName = "tk25",
  color = "grey",
  interactive = true
}) {

  const options = {
    fill: true,
    fillOpacity: 0,
    color: color,
    weight: 0.5,
    group: "TK25"
  };


  return (
    raster.map((tk25, index) => {
      const rectangle = [
        [tk25.south, tk25.west],
        [tk25.north, tk25.east],
      ];
      return (
        <Rectangle
          key={tk25[keyName]}
          layerId={tk25[keyName]}
          bounds={rectangle}
          pathOptions={options}
          interactive={false}
        >
        </Rectangle>
      );
    })
  );
}
