import React, { useState } from 'react';
import ReactTable from './ReactTable';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';
import { toast } from "react-hot-toast";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import EditObservationForm from './EditObservationForm';


export default function AdminMapTable(props) {

  const [observation, setObservation] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState(null);

  const handleDelete = async () => {
    try {
      // // Make API call to delete item
      // await axios.delete(`/api/items/${itemIdToDelete}`);

      // // Remove item from state
      // setItems(items.filter(item => item.id !== itemIdToDelete));

      // Close the modal
      setIsModalOpen(false);
    } catch (error) {
      console.error('Failed to delete item', error);
    }
  };

  console.log(props.observations);
  const data = React.useMemo(
    () =>
      props.observations,
    [props.observations]
  );

  const columns = React.useMemo(
    () => [
      {
        id: 'delete',
        Header: '',
        Cell: ({ row }) => (
          <Button variant="danger" className="btn-sm" onClick={() => props.handleShow(row.original.id)}>
            <FontAwesomeIcon icon={faTrash} />
          </Button>
        )
      },
      // {
      //   accessor: 'edit',
      //   Header: '',
      //   Cell: ({ row }) => (
      //     <Button variant="warning" className="btn-sm" onClick={() => {
      //       setSelectedRowId(row.original.id);
      //       setIsModalOpen(true);
      //       setObservation(row.original);
      //     }}>
      //       <FontAwesomeIcon icon={faEdit} />
      //     </Button>
      //   )
      // },
      {
        Header: 'ID',
        accessor: 'id',
      },
      {
        Header: 'Art',
        accessor: 'art',
        Cell: ({ cell: { value } }) => <i>{value}</i>
      },
      {
        Header: 'Datenquelle',
        accessor: 'datenquelle',
      },
      {
        Header: 'Sammler',
        accessor: 'sammler',
      },
      {
        Header: 'Det.',
        accessor: 'determiniert',
      },
      {
        Header: 'Jahr',
        accessor: 'jahr',
      },
      {
        Header: 'Datum',
        accessor: 'datum',
      },
    ],
    []
  );

  let mapTableUI;

  if (props.tk25Quadrant !== null) {
    mapTableUI = <>
      <h5>{props.tk25Quadrant}</h5>
      <ReactTable data={data} columns={columns} />
    </>
  } else {
    mapTableUI = <p style={{ color: "grey" }}>
      Es muss zuerst die Familie und dann eine Art ausgewählt werden, um die Verbreitung der Art auf der Karte anzuschauen.
      Bei Klick auf einen Kreis werden die zugehörigen Beobachtungen als Tabelle angezeigt.
    </p>;
  }

  return (
    <>
      {mapTableUI}
      <EditObservationForm
        observation={observation}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        selectedRowId={selectedRowId}
        handleDelete={handleDelete}
      />
      <Modal show={props.show} onHide={props.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Beobachtung wirklich löschen?</Modal.Title>
        </Modal.Header>
        <Modal.Body>Dies kann nicht rückgängig gemacht werden.</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.handleClose}>
            Nein
          </Button>
          <Button variant="danger" onClick={props.deleteObservation}>
            Ja
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
