import React from 'react';
import { Circle, Tooltip } from 'react-leaflet';

function getColorByYear(year) {
  let fillColor;
  if (year >= 2011) {
    fillColor = "black";
  } else if (year < 2011 && year >= 1990) {
    fillColor = "#808080";
  } else {
    fillColor = "white";
  }
  return (fillColor)
}


export default function ObservationCircles(props) {

  // Set radius based on region
  let radius = 2500; // tk25_quadrant
  if (["berlin", "hamburg", "muenchen"].includes(props.region)) {
    radius = 625; // tk25_64
  }

  // console.log(props);
  // create tk25 circles for observations
  // use unique to only render each circle once
  const circles = props.observations.map((row, index) => {

    const circleOptions = {
      group: "tk25.circles",
      color: "black",
      fillColor: getColorByYear(row.jahr),
      fillOpacity: 0.8,
      opacity: 0.8,
      weight: 1
    };

    return (
      <Circle
        key={index}
        layerId={row.tk25}
        center={[row.center_lat, row.center_lng]}
        pathOptions={circleOptions}
        // radius={radio.state === false || radio.state === "tk25_quadrant" ? 2500 : 5000}
        radius={radius}
        eventHandlers={{
          click: () => {
            props.handleMapClick(row.tk25);
          }
        }}
      >
        <Tooltip sticky>{row.tk25}</Tooltip>
      </Circle>
    );
  });

  return (circles)
}
