import React from 'react';
import { TileLayer, LayersControl } from 'react-leaflet';


function LayerControl() {

  return (
    // <LayersControl position="bottomright">
    //   <LayersControl.BaseLayer name="OpenStreetMap">
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
    //   </LayersControl.BaseLayer>
    //   <LayersControl.BaseLayer checked name="Stamen">
    //     <TileLayer
    //       url="https://stamen-tiles-{s}.a.ssl.fastly.net/terrain-background/{z}/{x}/{y}.jpg"
    //       attribution='Map tiles by <a href="http://stamen.com">Stamen Design</a>, <a href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a> &mdash; Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    //     />
    //   </LayersControl.BaseLayer>
    // </LayersControl>
  )
}

export default LayerControl;
