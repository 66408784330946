import React, { useState } from 'react';
import { Table } from 'react-bootstrap';

export default function Artportrait(props) {
  let artInfos = null;
  if (props.steckbrief) {
    artInfos = props.steckbrief[0];
  }

  return (
    artInfos === null | artInfos === undefined ?
      <></> :
      <div className='smallfont'>
        <h5><i>{artInfos.vollstaendiger_artname}</i></h5>
        <p>{artInfos.deutscher_name}</p>
        <hr></hr>
        <p><b>Rote Liste</b></p>
        <Table striped bordered size="sm">
          <thead>
            <tr style={{ textAlign: "center" }}>
              <th>DE</th>
              <th>BB</th>
              <th>BE</th>
              <th>BW</th>
              <th>BY</th>
              <th>HE</th>
              <th>MV</th>
              <th>NS</th>
              <th>NW</th>
              <th>RP</th>
              <th>SH</th>
              <th>SL</th>
              <th>SN</th>
              <th>ST</th>
              <th>TH</th>
            </tr>
          </thead>
          <tbody>
            <tr style={{ textAlign: "center" }}>
              <td className="align-right">{artInfos.rl_de}</td>
              <td>{artInfos.rl_bb}</td>
              <td>{artInfos.rl_be}</td>
              <td>{artInfos.rl_bw}</td>
              <td>{artInfos.rl_by}</td>
              {/* <td>{artInfos.rl_hb}</td>
              <td>{artInfos.rl_hh}</td> */}
              <td>{artInfos.rl_he}</td>
              <td>{artInfos.rl_mv}</td>
              <td>{artInfos.rl_ns}</td>
              <td>{artInfos.rl_nw}</td>
              <td>{artInfos.rl_rp}</td>
              <td>{artInfos.rl_sh}</td>
              <td>{artInfos.rl_sl}</td>
              <td>{artInfos.rl_sn}</td>
              <td>{artInfos.rl_st}</td>
              <td>{artInfos.rl_th}</td>
            </tr>
          </tbody>
        </Table>
        {artInfos && artInfos["letzter_nachweis"] ? <><span>Letzter Nachweis:</span> {artInfos["letzter_nachweis"]}<br /></> : null}
        {artInfos && artInfos["neufund"] ? <><span>Neunachweis:</span> {artInfos["neufund"]}<br /></> : null}
        <b>Lebensweise</b><br></br>
        {artInfos && artInfos["larvalnahrung"] ? <><span>Larvalnahrung:</span> {artInfos["larvalnahrung"]}<br /></> : null}
        {artInfos && artInfos["nistweise"] ? <><span>Nistweise:</span> {artInfos["nistweise"]}<br /><br /></> : null}
        <b>Anmerkungen</b><br></br>
        {artInfos && artInfos["kommentar"] ? <><span>Kommentar:</span> {artInfos["kommentar"]}<br /></> : null}
        {artInfos && artInfos["nomenklatur_und_synonymie"] ? <><span>Nomenklatur und Synonyme:</span> {artInfos["nomenklatur_und_synonymie"]}<br /><br /></> : null}
        <b>Systematik</b><br></br>
        {artInfos && artInfos["ueberfamilie"] ? <><span>Überfamilie:</span> {artInfos["ueberfamilie"]}<br /></> : null}
        {artInfos && artInfos["familiengruppe"] ? <><span>Familiengruppe:</span> {artInfos["familiengruppe"]}<br /></> : null}
        {artInfos && artInfos["familie"] ? <><span>Familie:</span> {artInfos["familie"]}<br /></> : null}
        {artInfos && artInfos["unterfamilie"] ? <><span>Unterfamilie:</span> {artInfos["unterfamilie"]}<br /></> : null}
        {artInfos && artInfos["untergattung_artengruppe"] ? <><span>Untergattung/Artengruppe:</span> {artInfos["untergattung_artengruppe"]}<br /></> : null}
        <hr></hr>
      </div>
  )
}
