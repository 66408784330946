import Papa from 'papaparse';

const parseFile = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = ({ target }) => {
      const csv = Papa.parse(target.result, {
        header: true,
        delimiter: ";",
        skipEmptyLines: true,
      });
      let parsedData = csv?.data;

      resolve(parsedData);
    };

    reader.onerror = (error) => reject(error);

    reader.readAsText(file);
  });
};

const checkSpecies = (parsedData, arten) => {

  // Remove last row if it's empty
  if (parsedData[parsedData.length - 1].art === '' || parsedData[parsedData.length - 1].art === undefined) {
    parsedData.pop();
  }

  // Check if all values of column art are present in database table arten
  const artenValues = parsedData.map((row, index) => ({ value: row.art, index }));
  const artenInDatabase = arten.map((row) => row.art);
  const artenInDatabaseSet = new Set(artenInDatabase);
  const problematicRows = artenValues.filter(({ value }) => !artenInDatabaseSet.has(value));
  console.log(problematicRows)
  let errorMessage = '';

  if (problematicRows.length > 0) {
    const problematicIndices = problematicRows.map(({ index }) => index + 1).join(', ');
    const problematicValues = [...new Set(problematicRows.map(({ value }) => value))].map((value) => `"${value}"`).join(', ');
    errorMessage += `Fehler: Die Arten ${problematicValues} in den Zeilen ${problematicIndices} sind nicht in der Datenbank vorhanden.\n`;
  }

  console.log(errorMessage)

  return errorMessage;
};

function checkCoordinates(parsedData) {
  // Define bounding box for Germany
  const germanyBoundingBox = {
    minLat: 47.270111,
    maxLat: 55.058347,
    minLon: 5.866315,
    maxLon: 15.041896
  };

  // Check if all latitude and longitude values are within Germany
  const problematicRows = parsedData.map((row, index) => ({ ...row, index })).filter(({ lat, lon, index }) => {
    const latitude = parseFloat(lat);
    const longitude = parseFloat(lon);
    return latitude < germanyBoundingBox.minLat || latitude > germanyBoundingBox.maxLat || longitude < germanyBoundingBox.minLon || longitude > germanyBoundingBox.maxLon;
  });

  return problematicRows;
}

function areSetsEqual(set1, set2) {
  if (set1.size !== set2.size) {
    return false;
  }
  for (let item of set1) {
    if (!set2.has(item)) {
      return false;
    }
  }
  return true;
}

const checkNames = (parsedData, names) => {

  // Check that names of parsedData are equal to names
  const namesValues = new Set(Object.keys(parsedData[0]));
  const namesSet = new Set(names);

  let errorMessage = '';
  if (!areSetsEqual(namesValues, namesSet)) {
    console.log("error")
    const problematicNames = [...namesValues].filter((value) => !namesSet.has(value));
    if (problematicNames.length > 0) {
      const problematicValues = problematicNames.map((value) => `"${value}"`).join(', ');
      errorMessage += `Fehler: Unbekannte Spalten Namen ${problematicValues} enthalten.\n`;
    }

    const namesMissing = [...namesSet].filter((value) => !namesValues.has(value));
    if (namesMissing.length > 0) {
      const missingValues = namesMissing.map((value) => `"${value}"`).join(', ');
      errorMessage += `Fehler: Die Spalten ${missingValues} fehlen in der hochgeladenen Datei.\n`;
    }
  }

  console.log(errorMessage)
  return errorMessage;
}

export { checkCoordinates, checkNames, checkSpecies, parseFile };
