// FileUploadForm.js
import React from 'react';
import { Form, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload } from '@fortawesome/free-solid-svg-icons';

const FileUploadForm = ({ handleFileChange, handleSubmit, disabled, uploading }) => {
  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group controlId="formFile" className="mb-3">
        <Form.Label>CSV Datei auswählen</Form.Label>
        <Form.Control type="file" onChange={handleFileChange} />
      </Form.Group>
      <Button variant="primary" type="submit" disabled={disabled}>
        <FontAwesomeIcon icon={faUpload} />
        {uploading ? ' Datei wird hochgeladen. Dies kann einen Moment dauern...' : ' Datei hochladen'}
      </Button>
    </Form>
  );
}

export default FileUploadForm;
