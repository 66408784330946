import { useContext, createContext } from "react";
import regions from './regions';

const subdomain = window.location.host.split(".")[0].toLowerCase();
let region = "deutschland";
let regionName = "Deutschland";
if (Object.keys(regions).includes(subdomain)) {
  region = subdomain;
  regionName = regions[region];
}

const regionContext = createContext(
  {
    region: region,
    regionName: regionName,
  }
);

function useRegion() {
  return useContext(regionContext);
}

export {
  regionContext,
  useRegion,
}
