import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import { NavLink, useNavigate } from 'react-router-dom';
import userfoto from '../www/user.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faUserCog } from "@fortawesome/free-solid-svg-icons";
import { faSignOut } from "@fortawesome/free-solid-svg-icons";
import { useAuth } from './AuthContext';
import './Navbar.css';

const config = {
  TITEL: "Stechimmen Atlas"
}

export default function NavbarComponent() {

  const auth = useAuth();
  const navigate = useNavigate();

  let navbarRight;
  if (!auth.user) {
    navbarRight =
      <Navbar.Text>
        <Nav.Link as={NavLink} to="/login">Admin</Nav.Link>
      </Navbar.Text>
  } else {
    navbarRight =
      <NavDropdown
        title={
          <span>
            <img className="rounded-circle margin-8" src={userfoto} width="32" height="32" alt=""></img>
            <span style={{ color: "white" }}> {auth.name} </span>
          </span>}
        id="nav-dropdown-user">
        {/* <NavDropdown.Item as={NavLink} to="/benutzer">
          Admins hinzufügen
        </NavDropdown.Item> */}
        {/* <NavDropdown.Divider /> */}
        <NavDropdown.Item
          as={Button}
          onClick={() => {
            auth.signout(() => navigate("/"));
          }}
        >
          <FontAwesomeIcon icon={faSignOut} /> Abmelden
        </NavDropdown.Item>
      </NavDropdown>

  }

  return (
    <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" fixed="top">
      <Container>
        <Navbar.Brand as={NavLink} to="/">
          <img
            alt=""
            src="../logo.png"
            // width="30"
            height="30"
            className="d-inline-block align-top"
          />{' '}
          <span className="title">{config.TITEL}</span>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link as={NavLink} to="/verbreitung">Verbreitung</Nav.Link>
            <Nav.Link as={NavLink} to="/artenliste">Artenliste</Nav.Link>
            <Nav.Link as="a" href="https://stechimmenatlas.de/verbreitung" rel="noopener noreferrer" target='_blank'>Zur Deutschland Karte</Nav.Link>
            {
              auth.user && (
                <>
                  <Nav.Link as={NavLink} to="/adminverbreitung">Admin Verbreitung</Nav.Link>
                  {
                    auth.admin && (
                      <Nav.Link as={NavLink} to="/steckbriefe">Steckbriefe</Nav.Link>
                    )}
                  <Nav.Link as={NavLink} to="/datenimport">Daten Import</Nav.Link>
                </>
              )
            }
          </Nav>
          {navbarRight}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}
