import React from 'react';
import './LeafletLegendZeitraum.css';
  

export default function LeafletLegendZeitraum() {
  return (
    <div className={`leaflet-bottom leaflet-right panel`}>
      <i className="legend-circle" style={{ background: "black" }}></i>{" "}
      <div className="legend-text">ab 2011</div>
      <br></br>
      <i className="legend-circle" style={{ background: "#808080" }}></i>{" "}
      <div className="legend-text">1990 - 2010</div>
      <br></br>
      <i className="legend-circle" style={{ background: "white" }}></i>{" "}
      <div className="legend-text">bis 1989</div>
    </div>
  )
}
