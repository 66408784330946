import React from 'react';
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import de from 'date-fns/locale/de';
import { Controller, useFormContext } from 'react-hook-form';

registerLocale('de', de)

const DatePickerField = ({ name, ...props }) => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <DatePicker
          {...field}
          {...props}
          showYearDropdown
          showMonthDropdown
          dropdownMode="select"
          locale="de"
          dateFormat="dd. MMMM yyyy"
          className="form-control"
          maxDate={new Date()}
          selected={(field.value && new Date(field.value)) || null}
        />
      )}
    />
  );
};

export default DatePickerField;
