// React Component for data import:
// User can upload csv file with new observations

import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Table from "react-bootstrap/Table";
import { MapContainer, TileLayer, Tooltip, CircleMarker } from 'react-leaflet';
import { useAuth } from "./AuthContext";
import axios from "./AxiosConfig";
import { toast } from "react-hot-toast";
import FileUploadForm from "./FileUploadForm";
import { checkNames, checkSpecies, parseFile } from './helpers';
import { uploadData } from "./uploadData";
import { Button } from "react-bootstrap";

const namesImport = [
  'familie', 'gattung', 'art', 'datum', 'anzahl', 'anzahl_m', 'anzahl_w',
  'bundesland_darstellung', 'fundort',
  'longitude', 'latitude', 'tk25', 'tk25_quadrant', 'tk25_16', 'tk25_64',
  'jahr', 'sammler', 'determiniert', 'datenquelle',
];

export default function Datenimport(props) {

  const auth = useAuth();

  const [file, setFile] = useState(null);
  const [message, setMessage] = useState(null);
  const [color, setColor] = useState("green");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [data, setData] = useState([]);
  const [isSubmittingData, setIsSubmittingData] = useState(false);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
    setIsDisabled(false);
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    // Get all values of art from database
    axios.get("/arten/all")
      .then(async (response) => {
        setIsSubmitting(true);
        setIsDisabled(true);
        const arten = response.data;

        let parsedData = await parseFile(file);
        console.log(parsedData);
        let errorMessage = "";

        // Convert all column names to lowercase
        for (let i = 0; i < parsedData.length; i++) {
          for (let key in parsedData[i]) {
            let newKey = key.toLowerCase();
            if (newKey !== key) {
              parsedData[i][newKey] = parsedData[i][key];
              delete parsedData[i][key];
            }
          }
        }

        parsedData = parsedData.filter(item => Object.keys(item).length !== 0);
        parsedData = parsedData.filter(item => item.art !== undefined && item.art !== "" && item.art !== " ");

        // for (let i = 0; i < parsedData.length; i++) {
        //   for (let key in parsedData[i]) {
        //     let newKey = key.toLowerCase();
        //     if (newKey !== key) {
        //       parsedData[i][newKey] = parsedData[i][key];
        //       delete parsedData[i][key];
        //     }
        //   }
        // }

        // Filter out all columns that are not in namesImport
        parsedData = parsedData.map(row => {
          let filteredRow = {};
          namesImport.forEach(name => {
            if (name !== "" && row.hasOwnProperty(name)) {
              filteredRow[name] = row[name];
            }
          });
          return filteredRow;
        });

        // Replace comma with dot in latitude and longitude columns
        parsedData = parsedData.map(row => {
          if (row['latitude']) {
            row['latitude'] = row['latitude'].replace(',', '.');
          }
          if (row['longitude']) {
            row['longitude'] = row['longitude'].replace(',', '.');
          }
          return row;
        });

        console.log(parsedData);

        const optionalColumns = ['tk25', 'tk25_quadrant', 'tk25_16', 'tk25_64'];

        // Filter out the names to skip
        let filteredNamesImport = namesImport.filter(name => !optionalColumns.includes(name));

        // Append to errorMessage if there are any errors
        errorMessage += checkNames(parsedData, filteredNamesImport);

        // Add art to parsedData
        for (let i = 0; i < parsedData.length; i++) {
          parsedData[i].art = parsedData[i].gattung + " " + parsedData[i].art;
        }
        errorMessage += checkSpecies(parsedData, arten);
        console.log(errorMessage);

        if (errorMessage === '') {
          setData(parsedData);
          setIsSubmitting(false);
          setIsDisabled(false);
        } else {
          setColor("red");
          setMessage(errorMessage);
          toast.error(errorMessage);
          setIsSubmitting(false);
          setIsDisabled(false);
        }
      });
  }

  const handleDataSubmit = () => {
    setIsSubmittingData(true);
    uploadData(data, "/beobachtungen")
      .then((response) => {
        setIsSubmittingData(false);
        setColor("green");
        setMessage(response.data);
        setData([]);
        toast.success(response.data);
      })
      .catch((error) => {
        console.log(error);
        setColor("red");
        setData([]);
        setMessage(error.response.data.error);
        toast.error(error.response.data.error);
        setIsSubmittingData(false);
      });
  }

  return (
    <>
      <Container className="panel">
        <h2>Daten Import</h2>
        <p>Lade neue Beobachtungen als csv Datei hoch.</p>
        <FileUploadForm handleFileChange={handleFileChange} handleSubmit={handleSubmit} disabled={isDisabled} uploading={isSubmitting} />
        <p style={{ color: color }}><b>{message}</b></p>
        <hr></hr>
        {/* Datenvorschau */}
        {
          data.length > 0 && <h3>Vorschau der Daten</h3> ?
            <>
              <MapContainer center={[51.1657, 10.4515]} zoom={6} style={{ height: "600px", width: "100%" }}>
                <TileLayer
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                />
                {data.map((row, index) => {
                  if (row.latitude && row.longitude) {
                    return (
                      <CircleMarker key={index} center={[parseFloat(row.latitude), parseFloat(row.longitude)]}>
                        <Tooltip>
                          <i>{row.art}</i><br />
                          Fundort: {row.fundort}<br />
                          Sammler: {row.sammler}<br />
                          Datenquelle: {row.datenquelle}
                        </Tooltip>
                      </CircleMarker>
                    );
                  }
                })}
              </MapContainer>
              <hr></hr>
              Wenn die Daten korrekt sind, klicke auf "Daten speichern".<br></br><br></br>
              <Button variant="success" onClick={handleDataSubmit} disabled={isSubmittingData}>Daten speichern</Button>
            </>
            : <>
              <p>Die CSV-Datei muss folgende Spalten enthalten:</p>
              <ul>
                <li>Gattung</li>
                <li>Art</li>
                <li>Jahr</li>
                <li>Datum</li>
                <li>Anzahl</li>
                <li>Anzahl_M</li>
                <li>Anzahl_W</li>
                <li>Bundesland_Darstellung</li>
                <li>Fundort</li>
                <li>Longitude</li>
                <li>Latitude</li>
                <li>TK25</li>
                <li>TK25_Quadrant</li>
                <li>TK25_16</li>
                <li>TK25_64</li>
                <li>Sammler</li>
                <li>Determiniert</li>
                <li>Datenquelle</li>
              </ul>
              <p>Jede Zeile enthält die Informationen zu einer Beobachtung.</p>
              <p>Beispiel:</p>
              <Table striped bordered hover size="sm">
                <thead>
                  <tr>
                    <th>Gattung</th>
                    <th>Art</th>
                    <th>Jahr</th>
                    <th>Datum</th>
                    <th>Anzahl</th>
                    <th>Anzahl_M</th>
                    <th>Anzahl_W</th>
                    <th>Bundesland_Darstellung</th>
                    <th>Fundort</th>
                    <th>Longitude</th>
                    <th>Latitude</th>
                    <th>TK25</th>
                    <th>TK25_Quadrant</th>
                    <th>TK25_16</th>
                    <th>TK25_64</th>
                    <th>Sammler</th>
                    <th>Determiniert</th>
                    <th>Datenquelle</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Andrena</td>
                    <td>alfkenella</td>
                    <td>2022</td>
                    <td>28.07.2022</td>
                    <td>1</td>
                    <td>0</td>
                    <td>1</td>
                    <td>Berlin</td>
                    <td>Berlin, Lichtenberg, Wuhletalbrücke-Park West</td>
                    <td>13,558</td>
                    <td>52,564</td>
                    <td>3447</td>
                    <td>1</td>
                    <td>14</td>
                    <td>141</td>
                    <td>Mustermann</td>
                    <td>CSE</td>
                    <td>CSE</td>
                  </tr>
                </tbody>
              </Table>
              Die Koordinaten müssen im WGS84 (EPSG: 4326) Format vorliegen.
              Falls die Koordinaten unbekannt sind, ist es auch möglich, Daten mit TK25 Quadranten hochzuladen.
              In diesem Fall bleiben die Longitude und Latitude Spalten leer, stattdessen werden die
              Spalten TK25 (vierstellige Zahl) und TK25_Quadrant (Zahl von 1 bis 4) benötigt.
            </>
        }
      </Container>
      <br></br>
    </>
  )
}
