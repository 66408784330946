import React from 'react';
import { useForm } from 'react-hook-form';
import Form from 'react-bootstrap/Form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import SubmitButton from './SubmitButton';
import { Link, useNavigate } from 'react-router-dom';

import { useAuth } from "./AuthContext";
import './Auth.css';


export default function Registrierung() {

  const navigate = useNavigate();
  const auth = useAuth();

  const validationSchema = Yup.object({
    vorname: Yup.string()
      .required('Pflichtfeld'),
    nachname: Yup.string()
      .required('Pflichtfeld'),
    email: Yup.string()
      .email('E-Mail Addresse ungültig!')
      .required('Pflichtfeld'),
    code: Yup.string()
      .required('Pflichtfeld'),
    passwort: Yup.string()
      .min(8, 'Das Passwort muss mindestens 8 Zeichen lang sein!')
      .required('Pflichtfeld'),
    passwort2: Yup.string()
      .oneOf([Yup.ref('passwort'), null], 'Passwörter müssen übereinstimmen!'),
  }).required();

  const { register, handleSubmit, watch, formState: { errors } } = useForm({
    resolver: yupResolver(validationSchema)
  });
  const onSubmit = values => {
    console.log(values);
    auth.register(() => {
      navigate("/verbreitung");
    }, values.nachname, values.vorname, values.email, values.passwort, values.code);
  }

  return (
    <div>
      <div className="auth-form-wide">
        <div className="auth-form-body-wide">
          <h3>Registrierung</h3>
          <hr></hr>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group>
              <Form.Group controlId="email">
                <Form.Label>E-Mail*</Form.Label>
                <Form.Control
                  type={'email'}
                  {...register("email")}
                />
                <p className="error">{errors.email?.message}</p>
              </Form.Group>
              <Form.Group controlId="code">
                <Form.Label>Registrierungscode*
                  <br></br>
                  <small style={{ color: "grey" }}>Hier den per E-Mail erhaltenen Code eintragen</small>
                </Form.Label>
                <Form.Control
                  type={'text'}
                  {...register("code")}
                />
                <p className="error">{errors.email?.message}</p>
              </Form.Group>
              <br></br>
              <h5>Persönliche Angaben</h5>
              <Form.Group controlId="vorname">
                <Form.Label>Vorname*</Form.Label>
                <Form.Control
                  type={'text'}
                  {...register("vorname")}
                  placeholder="Max"
                />
                <p className="error">{errors.vorname?.message}</p>
              </Form.Group>
              <Form.Group controlId="nachname">
                <Form.Label>Nachname*</Form.Label>
                <Form.Control
                  type={'text'}
                  {...register("nachname")}
                  placeholder="Mustermann"
                />
                <p className="error">{errors.nachname?.message}</p>
              </Form.Group>
              <br></br>
              <h5>Passwort</h5>
              <Form.Group controlId="passwort">
                <Form.Label>Passwort*</Form.Label>
                <Form.Control
                  type={'password'}
                  {...register("passwort")}
                />
                <p className="error">{errors.passwort?.message}</p>
              </Form.Group>
              <Form.Group controlId="passwort2">
                <Form.Label>Passwort wiederholen*</Form.Label>
                <Form.Control
                  type={'password'}
                  {...register("passwort2")}
                />
              </Form.Group>
              <p className="error">{errors.passwort2?.message}</p>
            </Form.Group>
            <br></br>
            <SubmitButton text={"Registrieren"} />
          </Form>
        </div>
      </div>
      <br></br>
    </div>
  )
}
