import React, { useEffect, useRef, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';

import Map from './Map';
import Artportrait from './Artportrait';
import InputForm from './InputForm';
import MapTable from './MapTable';
import { useRegion } from './RegionContext';
import axios from './AxiosConfig';

export default function Verbreitung(props) {

  // const map = useRef(null);
  const region = useRegion();

  const [art, setArt] = useState("");
  const [observations, setObservations] = useState([]);
  const [observationsTable, setObservationsTable] = useState([]);
  const [tk25Quadrant, setTK25Quadrant] = useState(null);
  const [artInfos, setArtInfos] = useState([]);

  const getObservations = async (art) => {
    try {
      let query = `beobachtungen?art=${art}`;
      if (region.region !== "deutschland") {
        query = query.concat(`&bundesland_darstellung=${region.regionName}`);
      }
      const response = await axios.get(query);
      if (response.data) {
        console.log(response.data);
        setObservations(response.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getArtenSteckbrief = async (art) => {
    try {
      const query = `steckbriefe?art=${art}`;
      const response = await axios.get(query);
      if (response.data) {
        console.log(response.data);
        setArtInfos(response.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const filterObservations = (event) => {
    console.log(event);
    if (event) {
      const art = event.value;
      setArt(art);
      getObservations(art);
      getArtenSteckbrief(art);
      // const current = defaultObs.filter((row) => row.art === art);
      // console.log(art);
      // setObservations(current);
      // setObservationsTable(current);
      setTK25Quadrant(null);
    }
  }

  const handleMapClick = (tk25) => {
    console.log(tk25);
    setTK25Quadrant(tk25);
    let current = observations.filter((row) => row.tk25 === tk25);
    if (art) {
      current = current.filter((row) => row.art === art);
    }
    console.log(current);
    setObservationsTable(current);
  }

  return (
    <>
      <Container>
        <br></br>
        <Row>
          <InputForm
            art={art}
            onChange={filterObservations}
            setArt={setArt}
            setTK25Quadrant={setTK25Quadrant}
          />
        </Row>
        <hr></hr>
        <Row>
          <Col md={6} xs={12}>
            <Map
              // map={map}
              region={region}
              handleMapClick={handleMapClick}
              observations={observations}
            />
          </Col>
          <Col md={6} xs={12}>
            <Container className="panel">
              <Artportrait steckbrief={artInfos} />
              <MapTable
                observations={observationsTable}
                tk25Quadrant={tk25Quadrant}
              />
            </Container>
          </Col>
        </Row>
      </Container>
    </>
  )
}
