// React Module to import csv data
// Send data to Express API to update the artensteckbriefe table

import React, { useState } from 'react';
import { useAuth } from './AuthContext';
import { Container, Row, Col, Table } from 'react-bootstrap';
// import './Steckbriefe.css';
import { toast } from 'react-hot-toast';
// import { uploadFile } from './uploadFile';
import FileUploadForm from './FileUploadForm';
import { checkNames, checkSpecies, parseFile } from './helpers';
import axios from "./AxiosConfig";

const namesSteckbriefe = [
  "LfNr",
  "Gruppe",
  "Status",
  "Familiengruppe",
  "Gattung",
  "Epithet",
  "Autor",
  "Vollständiger Artname",
  "Kommentar",
  "Nomenklatur und Synonymie",
  "Deutscher Name",
  "DE",
  "BY",
  "BW",
  "BE",
  "BB",
  "HE",
  "MV",
  "NS",
  "NW",
  "RP",
  "SL",
  "SN",
  "ST",
  "SH",
  "TH",
  "Letzter Nachweis",
  "Neufund",
  "Nistweise",
  "Larvalnahrung",
  "Überfamilie",
  "Familie",
  "Unterfamilie",
  "Untergattung/Artengruppe"
];

export const uploadJSON = (parsedData, endpoint) => {

  const data = new FormData();
  data.append('file', JSON.stringify(parsedData)); // jsonData is a JSON object
  
  // console.log(data);
  return axios.post(endpoint, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

export default function Steckbriefe() {

  const auth = useAuth();

  const [file, setFile] = useState(null);
  const [message, setMessage] = useState(null);
  const [color, setColor] = useState("green");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [data, setData] = useState([]);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    // Get all values of art from database
    axios.get("/arten/all")
      .then(async (response) => {
        setIsSubmitting(true);
        const arten = response.data;

        let parsedData = await parseFile(file);
        parsedData.pop();

        setData(parsedData);
        // console.log(parsedData);

        let errorMessage = "";
        // Append to errorMessage if there are any errors
        errorMessage += await checkNames(parsedData, namesSteckbriefe);

        // Add art to parsedData
        for (let i = 0; i < parsedData.length; i++) {
          parsedData[i].art = parsedData[i].Gattung + " " + parsedData[i].Epithet;
        }
        errorMessage += await checkSpecies(parsedData, arten);

        // Convert all column names to lowercase
        for (let i = 0; i < parsedData.length; i++) {
          for (let key in parsedData[i]) {
            let newKey = key.toLowerCase();
            if (newKey !== key) {
              parsedData[i][newKey] = parsedData[i][key];
              delete parsedData[i][key];
            }
          }
        }
        // Rename columns
        const propertyMapping = {
          "vollständiger artname": "vollstaendiger_artname",
          "nomenklatur und synonymie": "nomenklatur_und_synonymie",
          "untergattung/artengruppe": "untergattung_artengruppe",
          "letzter nachweis": "letzter_nachweis",
          "deutscher name": "deutscher_name",
          "überfamilie": "ueberfamilie"
        };

        for (let i = 0; i < parsedData.length; i++) {
          for (const oldProp in propertyMapping) {
            const newProp = propertyMapping[oldProp];
            parsedData[i][newProp] = parsedData[i][oldProp];
            delete parsedData[i][oldProp];
          }
        }

        // parsedData art = gattung + " " + epithet
        for (let i = 0; i < parsedData.length; i++) {
          parsedData[i].art = parsedData[i].gattung + " " + parsedData[i].epithet;
        }

        // console.log(parsedData);
        if (errorMessage === '') {
          uploadJSON(parsedData, "/steckbriefe")
            .then((response) => {
              console.log(response);
              setColor("green");
              setMessage(response.data);
              toast.success(response.data);
              setIsSubmitting(false);
            })
            .catch((error) => {
              console.log(error);
              setColor("red");
              setMessage(error.response.data.error);
              toast.error(error.response.data.error);
              setIsSubmitting(false);
            });
        } else {
          setColor("red");
          setMessage(errorMessage);
          toast.error(errorMessage);
          setIsSubmitting(false);
        }
      });
  }

  return (
    <Container className='panel'>
      <Row>
        <Col>
          <h2>Arten Steckbriefe</h2>
          <p>Überschreibe die Informationen zu den Artensteckbriefen mit einer CSV-Datei.</p>
          <FileUploadForm
            handleFileChange={handleFileChange}
            handleSubmit={handleSubmit}
            disabled={isSubmitting}
            uploading={isSubmitting}
          />
          <p style={{ color: color }}><b>{message}</b></p>
          <hr></hr>
          {data.length > 0 ? <h4>Vorschau der Daten</h4> : null}
          {data.length > 0 ?
            <Table striped bordered hover>
              <thead>
                <tr>
                  {Object.keys(data[0]).map((key) => (
                    <th key={key}>{key}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => (
                  <tr key={index}>
                    {Object.values(item).map((value, i) => (
                      <td key={i}>{value}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </Table>
            : null}
          <p>Die CSV-Datei muss folgende Spalten enthalten:</p>
          <ul>
            <li>LfNr</li>
            <li>Gruppe</li>
            <li>Status</li>
            <li>Familiengruppe</li>
            <li>Gattung</li>
            <li>Epithet</li>
            <li>Autor</li>
            <li>Vollständiger.Artname</li>
            <li>Kommentar</li>
            <li>Nomenklatur.und.Synonymie</li>
            <li>Deutscher.Name</li>
            <li>DE</li>
            <li>BY</li>
            <li>BW</li>
            <li>BE</li>
            <li>BB</li>
            <li>HE</li>
            <li>MV</li>
            <li>NS</li>
            <li>NW</li>
            <li>RP</li>
            <li>SL</li>
            <li>SN</li>
            <li>ST</li>
            <li>SH</li>
            <li>TH</li>
            <li>Letzter.Nachweis</li>
            <li>Neufund</li>
            <li>Nistweise</li>
            <li>Larvalnahrung</li>
            <li>Überfamilie</li>
            <li>Familie</li>
            <li>Unterfamilie</li>
            <li>Untergattung/Artengruppe</li>
          </ul>
          <p>Jede Zeile enthält die Informationen zu einer Art.</p>
        </Col>
      </Row>
    </Container>
  );
}
