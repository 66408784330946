import React from 'react';
import Button from 'react-bootstrap/Button';

export default function SubmitButton(props) {
  return (
    <div className="d-grid gap-2">
      <Button variant="success" type="submit">
        { props.text }
      </Button>
    </div>
  )
}
