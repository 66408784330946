import React, { useEffect, useState } from 'react';

import axios from 'axios';
import Container from 'react-bootstrap/Container';
import { useRegion } from './RegionContext';


export default function Homepage(props) {

  const region = useRegion();
  console.log(region);

  const [news, setNews] = useState();

  let query = "https://wp.stechimmenatlas.de/wp-json/wp/v2/pages/?slug=homepage";
  if (region.region !== "deutschland") {
    query = query.concat("-").concat(region.region);
  }

  useEffect(() => {
    axios.get(
      query
    ).then(response => {
      console.log(response.data);
      console.log(response.data[0].content.rendered);
      if (response.data) {
        setNews({"__html": response.data[0].content.rendered});
      }
    }).catch(function (error) {
      console.log(error);
      // toast.error("Falsche Anmeldedaten!");
    });
  }, []);

  return (
    <>
      <br></br>
      <Container>
        <br></br>
        <div dangerouslySetInnerHTML={news} />
      </Container>
    </>
  )
}
