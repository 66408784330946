import React, { useEffect } from "react";
import { useForm, FormProvider } from 'react-hook-form';
import regions from "./regions";
import DatePickerField from "./DatePicker";
import Button from 'react-bootstrap/Button';
import Col from "react-bootstrap/Col";
import Form from 'react-bootstrap/Form';
import Row from "react-bootstrap/Row";
import Modal from 'react-bootstrap/Modal';

// Use react-bootstrap form inputs to edit data
// art, datum, jahr, datenquelle, sammler, determiniert, anzahl, anzahl_m, anzahl_w, 
// bundesland_darstellung, bundesland, tk25, tk25_quadrant, tk25_16, tk25_64,
// longitude, latitude, id_extern

export default function EditObservationForm(props) {


  const methods = useForm({});
  const { register, handleSubmit, setValue, formState: { errors } } = methods;

  useEffect(() => {
    if (props.observation) {
      setValue("art", props.observation.art);
      setValue("datum", props.observation.datum);
      setValue("jahr", props.observation.jahr);
      setValue("datenquelle", props.observation.datenquelle);
      setValue("sammler", props.observation.sammler);
      setValue("determiniert", props.observation.determiniert);
      setValue("anzahl", props.observation.anzahl);
      setValue("anzahl_m", props.observation.anzahl_m);
      setValue("anzahl_w", props.observation.anzahl_w);
      setValue("bundesland_darstellung", props.observation.bundesland_darstellung);
      setValue("latitude", props.observation.latitude);
      setValue("longitude", props.observation.longitude);
      setValue("fundort", props.observation.fundort);
      setValue("bemerkungen", props.observation.bemerkungen);
      // setValue("tk25", props.observation.tk25);
      // setValue("tk25_16", props.observation.tk25_16);
      // setValue("tk25_64", props.observation.tk25_64);
    }
  }, [props.observation, setValue]);

  return (
    <>
      <FormProvider {...methods}>
        <Form onSubmit={methods.handleSubmit(data => console.log(data))}>
          < Modal size="lg" show={props.isModalOpen} onHide={() => props.setIsModalOpen(false)
          }>
            <Modal.Header closeButton>
              <Modal.Title>Beobachtung editieren</Modal.Title>
            </Modal.Header>
            <Modal.Body>

              <Row>
                <Col md={6}>
                  <Form.Group controlId="art">
                    <Form.Control type="text" placeholder="Art" {...register("art", { required: true })} />
                  </Form.Group>
                  <Row className="g-2">
                    <Col md={6}>
                      <Form.Text>Datum</Form.Text>
                      <DatePickerField name="datum" />
                    </Col>
                    <Col md={6}>
                      <Form.Text>Jahr</Form.Text>
                      <Form.Group controlId="jahr">
                        <Form.Control type="number" placeholder="Jahr" {...register("jahr", { required: true, max: 3000, min: 1000, maxLength: 4, pattern: /[0-9]{4}/i })} />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Form.Group controlId="datenquelle">
                    <Form.Text>Datenquelle</Form.Text>
                    <Form.Control type="text" placeholder="Datenquelle" {...register("datenquelle", {})} />
                  </Form.Group>
                  <Form.Group controlId="sammler">
                    <Form.Text>Sammler</Form.Text>
                    <Form.Control type="text" placeholder="Sammler" {...register("sammler", {})} />
                  </Form.Group>
                  <Form.Group controlId="determiniert">
                    <Form.Text>Determiniert</Form.Text>
                    <Form.Control type="text" placeholder="Determiniert" {...register("determiniert", {})} />
                  </Form.Group>
                  <Row className="g-2">
                    <Col md={4}>
                      <Form.Group controlId="anzahl">
                        <Form.Text>Anzahl</Form.Text>
                        <Form.Control type="number" placeholder="Anzahl" {...register("anzahl", { max: 100000, min: 0 })} />
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      <Form.Group controlId="anzahl_m">
                        <Form.Text>Anzahl Männchen</Form.Text>
                        <Form.Control type="number" placeholder="Männchen" {...register("anzahl_m", { max: 100000, min: 0 })} />
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      <Form.Group controlId="anzahl_w">
                        <Form.Text>Anzahl Weibchen</Form.Text>
                        <Form.Control type="number" placeholder="Weibchen" {...register("anzahl_w", { max: 100000, min: 0 })} />
                      </Form.Group>
                    </Col>
                  </Row>

                </Col>
                <Col md={6}>
                  <Form.Group controlId="bundesland_darstellung">
                    <Form.Text>Bundesland Darstellung</Form.Text>
                    <Form.Select {...register("bundesland_darstellung")}>
                      {
                        Object.values(regions).map((location) => (
                          <option value={location}>{location}</option>
                        ))
                      }
                    </Form.Select>
                  </Form.Group>
                  <Row className="g-2">
                    <Col md={6}>
                      <Form.Group controlId="longitude">
                        <Form.Text>Längengrad</Form.Text>
                        <Form.Control type="number" placeholder="11,15" {...register("longitude", { min: 5.866, max: 15.042 })} />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group controlId="latitude">
                        <Form.Text>Breitengrad</Form.Text>
                        <Form.Control type="number" placeholder="48,2" {...register("latitude", { min: 47.270, max: 55.057 })} />
                      </Form.Group>
                    </Col>
                  </Row>
                  {/* <Row className="g-2">
                    <Col md={6}>
                      <Form.Text>TK25</Form.Text>
                      <Form.Group controlId="tk25">
                        <Form.Control type="text" placeholder="TK25" {...register("tk25", { maxLength: 4, pattern: /[0-9]{4}/i })} />
                      </Form.Group>
                    </Col>
                    <Col md={2}>
                      <Form.Text>1/4</Form.Text>
                      <Form.Group controlId="tk25_quadrant">
                        <Form.Control type="number" placeholder="2" {...register("tk25_quadrant", { min: 1, max: 4 })} />
                      </Form.Group>
                    </Col>
                    <Col md={2}>
                      <Form.Text>1/16</Form.Text>
                      <Form.Group controlId="tk25_16">
                        <Form.Control type="number" placeholder="3" {...register("tk25_16", { min: 1, max: 4 })} />
                      </Form.Group>
                    </Col>
                    <Col md={2}>
                      <Form.Text>1/64</Form.Text>
                      <Form.Group controlId="tk25_64">
                        <Form.Control type="number" placeholder="4" {...register("tk25_64", { min: 1, max: 4 })} />
                      </Form.Group>
                    </Col>
                  </Row> */}
                  <Form.Group controlId="fundort">
                    <Form.Text>Fundort</Form.Text>
                    <Form.Control type="text" placeholder="Fundort" {...register("fundort", {})} />
                  </Form.Group>
                  <Form.Group controlId="bemerkungen">
                    <Form.Text>Bemerkungen</Form.Text>
                    <Form.Control type="text" placeholder="Bemerkungen" {...register("bemerkungen", {})} />
                  </Form.Group>
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="light" onClick={() => props.setIsModalOpen(false)}>
                Abbrechen
              </Button>
              <Button variant="danger" onClick={props.handleDelete}>
                Löschen
              </Button>
              <Button variant="success" type="submit" onClick={() => props.setIsModalOpen(false)}>
                Speichern
              </Button>
            </Modal.Footer>
          </Modal >
        </Form>
      </FormProvider>
    </>
  )
}
