import React from 'react';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
// import SelectArt from './SelectArt';
import Select, { components } from 'react-select';
import AsyncSelect from 'react-select/async';

import axios from "./AxiosConfig";
import { useRegion } from './RegionContext';
import choicesFamilie from '../data/arten/choices_familie.json';


export default function InputForm(props) {

  const [choicesArt, setChoicesArt] = React.useState([]);
  const region = useRegion();

  const getChoicesArt = async (familie) => {
    try {
      let query = `arten`;
      query = query.concat(`?familie=${familie}`);
      if (region.region !== "deutschland") {
        query = query.concat(`&bundesland_darstellung=${region.regionName}`);
      }
      console.log(query);
      const response = await axios.get(query);
      console.log(response);
      if (response.data) {
        console.log(response.data);
        const choices = response.data.map((row) => {
          return { value: row.art, label: row.art }
        });
        setChoicesArt(choices);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getChoicesFamilie = (event) => {
    if (event) {
      const familie = event.value;
      getChoicesArt(familie)
    }
  }

  console.log(props.art)
  console.log(choicesArt)

  const selectStyles = { menuPortal: base => ({ ...base, zIndex: 9999 }) };

  const filterChoices = (inputValue) => {
    return choicesArt.filter((i) =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(filterChoices(inputValue));
      }, 500);
    });

  const groupStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  };

  const groupBadgeStyles = {
    backgroundColor: '#EBECF0',
    borderRadius: '2em',
    color: '#172B4D',
    display: 'inline-block',
    fontSize: 12,
    fontWeight: 'normal',
    lineHeight: '1',
    minWidth: 1,
    padding: '0.16666666666667em 0.5em',
    textAlign: 'center',
  };

  const formatGroupLabel = data => (
    <div style={groupStyles}>
      <span>{data.label}</span>
    </div>
  );

  const GroupHeading = props => (
    <div>
      <components.GroupHeading {...props} />
      <hr style={{ margin: 0 }} />
    </div>
  );


  return (
    <Form>
      <Form.Group as={Row}>
        <Form.Group as={Col} lg={3} md={6} xs={12}>
          <Form.Label>Familie</Form.Label>
          <Select
            menuPortalTarget={document.body}
            styles={selectStyles}
            className="basic-single"
            classNamePrefix="select"
            defaultValue={""}
            isLoading={false}
            isClearable={true}
            isSearchable={true}
            name="familie"
            options={choicesFamilie}
            onChange={getChoicesFamilie}
            placeholder="Familie"
            formatGroupLabel={formatGroupLabel}
            components={{ GroupHeading }}
            formatOptionLabel={function (data) {
              return (
                <span dangerouslySetInnerHTML={{ __html: data.label }} />
              );
            }}
          />
        </Form.Group>
        <Form.Group as={Col} lg={3} md={6} xs={12}>
          <Form.Label>Art</Form.Label>
          <AsyncSelect
            cacheOptions
            defaultOptions={choicesArt}
            menuPortalTarget={document.body}
            styles={selectStyles}
            // className="basic-single"
            // classNamePrefix="select"
            defaultValue={""}
            isLoading={false}
            isClearable={true}
            isSearchable={true}
            name="art"
            // options={choicesArt}
            onChange={props.onChange}
            placeholder="Art"
            loadOptions={promiseOptions}
          />
          {/* <SelectArt filterObservations={props.filterObservations}/> */}
        </Form.Group>
        {/* <Form.Group as={Col} md={3} xs={5}> */}
        {/* <SelectArt filterObservations={props.filterObservations}/> */}
        {/* <Form.Label>Art</Form.Label>
          <Select
            key={`my_unique_select_key__${props.art}`}
            menuPortalTarget={document.body}
            styles={selectStyles}
            className="basic-single"
            classNamePrefix="select"
            defaultValue={""}
            isLoading={false}
            isClearable={true}
            isSearchable={true}
            name="art"
            options={choicesArt}
            onChange={props.onChange}
            value= {{label: props.art, value: props.art, key : '001'}}
            placeholder="Art"
          />
        </Form.Group> */}
      </Form.Group>
    </Form>
  )
}