import React, { useRef } from 'react';
import { MapContainer, Polyline } from 'react-leaflet';
import { FullscreenControl } from "react-leaflet-fullscreen";
import "react-leaflet-fullscreen/styles.css";

import LayerControl from './LayerControl';
import ObservationCircles from './ObservationCircles';
import TK25Raster from './TK25Raster';
import tk25Raster from '../data/tk25/tk25_deutschland.json';
import tk25RasterRegionen from '../data/tk25/tk25_deutschland_nested.json';
// import 'leaflet-boundary-canvas';
// import L from 'leaflet';
import { uniqBy } from 'lodash';
import shapeDeutschland from '../data/gadm/shape_deutschland.json';
import regionenMittelpunkt from '../data/gadm/regionen_mittelpunkt.json';
import shapes from '../data/gadm/regionen.json';
import './Leaflet.css';
import LeafletLegendZeitraum from './LeafletLegendZeitraum';

function getRegionShape(region) {
  const regionDetails = regionenMittelpunkt.filter(row => row.Region === region)[0];
  const shape = shapes.filter(row => row.bundesland === region)[0];
  return {
    zoom: regionDetails.zoom,
    center: [regionDetails.latitude, regionDetails.longitude],
    tk25: tk25RasterRegionen[region],
    shape: shape.shape,
  }
}


// https://stackoverflow.com/questions/70945771/how-to-show-only-one-country-using-react-leaflet
export default function Map(props) {

  const map = useRef(null);

  console.log(props.region.region)

  // default: Deutschland
  let region = {
    center: [51.3, 10],
    zoom: 6,
    tk25: tk25Raster,
    shape: [shapeDeutschland],
  }
  // based on url get map details for region
  // let bundeslaender = <></>;
  if (props.region.region !== "deutschland") {
    region = getRegionShape(props.region.regionName);
  }
  const regionBorders = region.shape.map((row, index) => {
    return <Polyline key={index} pathOptions={{ weight: 2 }} positions={row} />
  });

  const bundeslaenderShapes = shapes.map((row, index) => {
    return <Polyline key={index + 100} pathOptions={{ weight: 1 }} positions={row.shape} />
  });

  // console.log(props.observations);

  return (
    <MapContainer
      center={region.center}
      zoom={region.zoom}
      scrollWheelZoom={true}
      height={400}
      ref={map}
    >
      <FullscreenControl position="topleft" />
      <LayerControl />
      {bundeslaenderShapes}
      {regionBorders}
      {/* <Polyline pathOptions={{ color: "grey", weight: 2 }} positions={shapeBayern} /> */}
      <TK25Raster raster={region.tk25} />
      <ObservationCircles
        observations={uniqBy(props.observations.filter(row => row.center_lat), "tk25")}
        region={props.region.region}
        handleMapClick={props.handleMapClick}
      />
      <LeafletLegendZeitraum />
    </MapContainer>
  )
}
