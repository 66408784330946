import React from 'react';
import Form from 'react-bootstrap/Form';
import { useForm } from 'react-hook-form';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import SubmitButton from './SubmitButton';
import { useAuth } from './AuthContext';
import './Auth.css';


export default function Login(props) {

  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { from } = location.state || { from: { pathname: "/verbreitung" } };

  const { register, handleSubmit, watch, formState: { errors } } = useForm();

  const onSubmit = (values) => {
    // event.preventDefault();
    console.log("Login");
    auth.signin(() => {
      navigate(from);
    }, values.email, values.passwort);
  };

  return (
    <div>
      <div className="auth-form">
        <div className="auth-form-body">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>E-Mail</Form.Label>
              <Form.Control
                type="email"
                {...register("email")}
              />
            </Form.Group>
            <Form.Group controlId="formBasicPassword">
              <Form.Label>Passwort</Form.Label>
              <Form.Control
                type="password"
                {...register("passwort")}
              />
            </Form.Group>
            <br></br>
            <SubmitButton text={"Anmelden"} />
            <hr></hr>
            <Link to="/passwort">Passwort vergessen?</Link>
            <br></br>
            <br></br>
            <p className="help">
              Die Anmeldung ist nur für Administratoren des Projekts möglich.
            </p>
          </Form>
        </div>
      </div>
      <br></br>
      <br></br>
    </div>
  )
}
