import axios from 'axios';
// import regions from './regions';

const subdomain = window.location.host.split(".")[0].toLowerCase();

// Because of the cookie-based authentication the domain of the app and API 
// needs to match, e.g. berlin.stechimmenatlas.de must call 
// api.berlin.stechimmenatlas.de and NOT api.stechimmenatlas.de.

let api = null;
const domain = process.env.REACT_APP_DOMAIN;
const port = process.env.REACT_APP_API_PORT;
const protocol = process.env.REACT_APP_PROTOCOL;

if (process.env.REACT_APP_DOMAIN !== "localhost") {
  // check if subdomain
  // if (Object.keys(regions).includes(subdomain)) {
  //   api = `${protocol}://${subdomain}.api.${domain}`;
  // } else {
  api = `${protocol}://api.${domain}`;
  // }
} else {
  // local development
  api = `${protocol}://${domain}:${port}`;
}
console.log(api);

const instance = axios.create({
  baseURL: api
});

instance.defaults.withCredentials = true;

export default instance;
