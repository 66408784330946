import React from 'react';

import {
  Routes,
  Route,
  Navigate,
  useLocation,
  // useSearchParams,
} from 'react-router-dom';

import { useAuth } from './AuthContext';
import Verbreitung from './Verbreitung';
import AdminVerbreitung from './AdminVerbreitung';
import Homepage from './Homepage';
import Impressum from './Impressum';
import Login from './Login';
import Benutzer from './Benutzer';
import Registrierung from './Registrierung';
import PasswordResetEmail from './PasswordResetEmail';
import PasswordResetForm from './PasswordResetForm';
import Artenliste from './Artenliste';
// import BeobachtungenTabelle from './BeobachtungenTabelle';
import Datenimport from './Datenimport';
import Steckbriefe from './Steckbriefe';

function RequireAuth({ children }) {
  let auth = useAuth();
  let location = useLocation();

  if (!auth.user) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return children;
}


export default function Routing() {

  // const [searchParams] = useSearchParams();
  // console.log(searchParams);

  // const auth = useAuth();

  return (
    <Routes>
      <Route exact path="/" element={<Homepage />} />
      <Route path="/impressum" element={<Impressum />} />
      <Route path="/artenliste" element={<Artenliste />} />
      {/* <Route path="/beobachtungen" element={<BeobachtungenTabelle />}/> */}
      <Route path="/verbreitung" element={<Verbreitung />} />
      <Route path="/login" element={<Login />} />
      <Route path="/registrierung" element={<Registrierung />} />
      <Route path="/passwort" element={<PasswordResetEmail />} />
      <Route path="/passwortneu" element={<PasswordResetForm />} />
      {/* Private Routes */}
      <Route path="/adminverbreitung"
        element={
          <RequireAuth>
            <AdminVerbreitung />
          </RequireAuth>
        } />
      <Route path="/datenimport"
        element={
          <RequireAuth>
            <Datenimport />
          </RequireAuth>
        } />
      <Route path="/steckbriefe"
        element={
          <RequireAuth>
            <Steckbriefe />
          </RequireAuth>
        } />
    </Routes>
  );
}
