import React, { useEffect, useState } from "react";
import toast, { Toaster } from 'react-hot-toast';

import axios from './AxiosConfig';
import { authContext } from "./AuthContext";


const fakeAuth = {
  isAuthenticated: false,
  signin(cb, email, password) {
    axios.post(
      `auth/login`,
      {
        email: email,
        password: password
      },
      {
        withCredentials: true
      }
    ).then(response => {
      console.log(response);
      console.log(response.data);
      cb(response.data.username, response.data.name, response.data.admin, response.data.id);
    }).catch(function (error) {
      console.log(error);
      toast.error("Falsche Anmeldedaten!");
    });
    // setTimeout(cb, 100); // fake async
  },
  signout(cb) {
    axios.post(`auth/logout`,
      {
        withCredentials: true
      }).then(res => {
        cb();
      });
  },
  register(cb, nachname, vorname, email, passwort, code) {
    axios.post(
      `auth/register`,
      {
        nachname: nachname,
        vorname: vorname,
        email: email,
        password: passwort,
        code: code,
      },
      {
        withCredentials: true
      }
    ).then(response => {
      console.log(response);
      console.log(response.data);
      toast.success(`Willkommen bei Stechimmen Atlas!`);
      cb(response.data.username, response.data.name);
    }).catch(function (error) {
      console.log(error);
      toast.error("Es ist ein Fehler aufgetreten!");
    });
  },
  getPasswordResetCode(cb, email) {
    axios.post(
      `auth/passwordresetcode`,
      {
        email: email
      },
      {
        withCredentials: true
      }
    ).then(response => {
      console.log(response.data);
      toast.success("Eine Verifizierungs E-Mail ist unterwegs!");
      cb();
    }).catch(function (error) {
      console.log(error);
      toast.error("Es ist ein Fehler aufgetreten!");
    });
  },
  resetPassword(cb, email, code, passwort) {
    axios.post(
      `auth/passwordreset`,
      {
        email: email,
        code: code,
        passwort: passwort
      },
      {
        withCredentials: true
      }
    ).then(response => {
      console.log(response.data);
      toast.success("Das Passwort wurde erfolgreich geändert!");
      cb();
    }).catch(function (error) {
      console.log(error);
      toast.error("Es ist ein Fehler aufgetreten!");
    });
  },
  changePassword(cb, passwordold, passwordnew) {
    axios.post(
      `auth/passwordchange`,
      {
        passwordold: passwordold,
        passwordnew: passwordnew
      },
      {
        withCredentials: true
      }
    ).then(response => {
      console.log(response.data);
      toast.success("Das Passwort wurde erfolgreich geändert!");
      cb();
    }).catch(function (error) {
      console.log(error);
      toast.error("Es ist ein Fehler aufgetreten!");
    });
  }
};

function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return (
    <authContext.Provider value={auth}>
      {children}
    </authContext.Provider>
  );
}

function useProvideAuth() {
  const [admin, setAdmin] = useState(null);
  const [user, setUser] = useState(null);
  const [name, setName] = useState(null);
  const [id, setID] = useState(null);
  const [loading, setLoading] = useState(true);

  const setState = (username, name, admin, id) => {
    setUser(username);
    setName(name);
    setAdmin(admin);
    setID(id);
  };

  // Check authentification status on the server sending the cookie with the session id
  useEffect(() => {
    const authenticate = async () => {
      console.log("authenticate");
      console.log(process.env.NODE_ENV);
      console.log(`authenticate`);
      await axios.get(`auth/authenticate`,
        {
          withCredentials: true
        }).then((response) => {
          if (response.data.auth) {
            setState(response.data.username, response.data.name, response.data.admin, response.data.id);
            // console.log("set cookie");
          }
          // console.log("setLoading");
          setLoading(false);
        });
    };

    authenticate();
  }, []);

  const signin = (cb, email, password) => {
    return fakeAuth.signin((username, name, admin, id) => {
      setState(username, name, admin, id);
      cb();
    },
      email,
      password
    );
  };

  const signout = cb => {
    return fakeAuth.signout(() => {
      setUser(null);
      setName(null);
      setAdmin(null);
      setID(null);
      cb();
    });
  };

  const register = (cb, nachname, vorname, email, passwort, adresse, ort, telefon) => {
    return fakeAuth.register((username, name, admin, id) => {
      setState(username, name, admin, id);
      cb();
    },
      nachname,
      vorname,
      email,
      passwort,
      adresse,
      ort,
      telefon);
  };

  const getPasswordResetCode = (cb, email) => {
    return fakeAuth.getPasswordResetCode(() => {
      cb();
    },
      email);
  };

  const resetPassword = (cb, email, code, passwort) => {
    return fakeAuth.resetPassword(() => {
      cb();
    },
      email, code, passwort)
  };

  const changePassword = (cb, passwordold, passwordnew) => {
    return fakeAuth.changePassword(() => {
      cb();
    }, passwordold, passwordnew)
  };

  return {
    user,
    name,
    admin,
    id,
    loading,
    changePassword,
    getPasswordResetCode,
    register,
    resetPassword,
    signin,
    signout
  };
}

export default ProvideAuth;
