import React from 'react';
import { useForm } from 'react-hook-form';
import Form from 'react-bootstrap/Form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';

import SubmitButton from './SubmitButton';
import { useAuth } from './AuthContext';
import axios from './AxiosConfig';
import './Auth.css';


export default function PasswordResetForm(props) {

  const navigate = useNavigate();  
  const auth = useAuth();

  const validationSchema = Yup.object({
    email: Yup.string()
      .email('E-Mail Addresse ungültig!')
      .required('Pflichtfeld'),
    code: Yup.string()
      .required('Pflichtfeld'),
    passwort: Yup.string()
      .min(8, 'Das Passwort muss mindestens 8 Zeichen lang sein!')
      .required('Pflichtfeld'),
    passwort2: Yup.string()
      .oneOf([Yup.ref('passwort'), null], 'Passwörter müssen übereinstimmen!'),
  });

  const { register, handleSubmit, watch, formState: { errors } } = useForm({
    resolver: yupResolver(validationSchema)
  });
  const onSubmit = (values) => {
    console.log(values);
    auth.resetPassword(() => {
      navigate("/login");
    }, values.email, values.code, values.passwort);
  }

  return (
    <div>
      <div className="auth-form">
        <div className="auth-form-body">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Text>
              Hier den per E-Mail erhaltenen Verifizierungscode einfügen
              und ein neues Passwort vergeben.
            </Form.Text>
            <hr></hr>
            <Form.Group controlId="email">
              <Form.Label>E-Mail</Form.Label>
              <Form.Control
                type={'email'}
                {...register("email")}
              />
              <p className="error">{errors.email?.message}</p>
            </Form.Group>
            <Form.Group controlId="code">
              <Form.Label>Verifizierungscode</Form.Label>
              <Form.Control
                type={'text'}
                {...register("code")}
              />
              <p className="error">{errors.code?.message}</p>
            </Form.Group>
            <Form.Group controlId="passwort">
              <Form.Label>Passwort</Form.Label>
              <Form.Control
                type={'password'}
                {...register("passwort")}
              />
              <p className="error">{errors.passwort?.message}</p>
            </Form.Group>
            <Form.Group controlId="passwort2">
              <Form.Label>Passwort wiederholen</Form.Label>
              <Form.Control
                type={'password'}
                {...register("passwort2")}
              />
              <p className="error">{errors.passwort2?.message}</p>
            </Form.Group>
            <br></br>
            <SubmitButton text={"Passwort ändern"} />
          </Form>
        </div>
      </div>
      <br></br>
    </div>
  )
}
